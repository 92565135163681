import { NgFor, NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  model,
  OnInit,
  Output,
  ViewChild,
  viewChild,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ExamsService } from '../../services/exams.service';
import { ExamModel } from '../../models/exam.model';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIcon } from '@angular/material/icon';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MessageService } from '../../services/message.service';
import { MatRipple } from '@angular/material/core';
import { Subscription } from 'rxjs';
import { IPaginatedResult } from '../../models/paginatedResult.model';
import { ApiService } from '../../services/api.service';
import { ProfilePictureComponent } from '../profile-picture/profile-picture.component';
import { MatSelectModule } from '@angular/material/select';

type Student = {
  id: string;
  active: boolean;
  avatarId: number;
  classroom_name: string;
  classroom_timeslot: string;
  gender: string;
  name: string;
  email: string;
  school_name: string;
};

@Component({
  selector: 'app-schedule-exam-dialog',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDatepickerModule,
    MatIcon,
    MatAutocompleteModule,
    MatRipple,
    ReactiveFormsModule,
    ProfilePictureComponent,
    MatSelectModule,
    FormsModule,
  ],
  templateUrl: './schedule-exam-dialog.component.html',
  styleUrl: './schedule-exam-dialog.component.scss',
})
export class ScheduleExamDialogComponent implements OnInit {
  readonly dialogRef = inject(MatDialogRef<ScheduleExamDialogComponent>);
  readonly data = inject<any>(MAT_DIALOG_DATA);

  onNoClick(): void {
    this.dialogRef.close();
  }

  @Output() closeModalEvent = new EventEmitter();
  @Output() submitFormEvent = new EventEmitter();

  @ViewChild('studentNameInput') studentNameInput!: ElementRef;

  private messageService = inject(MessageService);

  protected examTarget?: 'student' | 'lead';

  private apiService = inject(ApiService);

  protected student?: Student;

  searchSubscription?: Subscription;

  options: { id: string; name: string }[] = [];

  onSearch(): void {
    if (this.searchSubscription) this.searchSubscription.unsubscribe();

    const studentName = this.studentNameInput.nativeElement.value;

    if (studentName)
      this.searchSubscription = this.apiService
        .getByFilters<IPaginatedResult<Student>>('students/', {
          pageSize: 5,
          search: studentName,
        })
        .subscribe((response) => {
          this.options = response.items;
        });
  }

  displayFn(user: any): string {
    return user && user.name ? user.name : '';
  }

  onStudentSelected(event: MatAutocompleteSelectedEvent): void {
    if (this.student) {
      this.exam.fullName = this.student.name;
      this.exam.email = this.student.email;
      this.exam.studentId = this.student.id;
    }
  }

  showModal: boolean = false;
  exam: ExamModel;
  title: string = 'Agendar Prova';
  stageId: number = this.data['stageId'];
  showExamLink: boolean = false;

  students: {
    student_id: string;
    full_name: string;
    email: string;
    access_token: string;
  }[] = [];

  constructor(private examsService: ExamsService) {
    this.exam = new ExamModel();
    this.exam.startTime = new Date();
    this.examsService = examsService;
  }

  ngOnInit(): void {
    this.getStudents();
  }

  closeModal() {
    this.showModal = false;
    this.closeModalEvent.emit();
    this.showExamLink = false;
    this.exam = new ExamModel();
  }

  async submitForm() {
    //Validate email
    let emailRegex = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$');
    const isValidEmail =
      typeof this.exam.email == 'string' &&
      emailRegex.test(this.exam.email.toLowerCase());

    if (!isValidEmail) {
      this.messageService.alert(
        `O endereço de email "${this.exam.email}" é inválido`
      );
      return;
    }

    await this.examsService
      .createStudent({
        student_id: this.exam.studentId,
        full_name: this.exam.fullName,
        email: this.exam.email,
      })
      .toPromise()
      .then((response) => {
        if (!response.success) return;

        this.exam.studentId = response.data.student_id;
        this.exam.accessToken = response.data.access_token;
      });

    this.examsService
      .get('exams', {
        attribute_behaviour: 'create',
        student_id: this.exam.studentId,
        duration_minutes: this.exam.duration,
        stage_id: this.stageId,
        start_time: this.exam.startTime,
      })
      .toPromise()
      .then((response) => {
        if (!response.success) return;

        this.exam.examId = response.data.exam_id;
        this.showExamLink = true;
      });
  }

  getStudents() {
    this.examsService
      .get('students')
      .toPromise()
      .then((response) => {
        if (response.success) {
          this.students = response.data;
        }
      });
  }

  getStudentEmail() {
    let student = this.students.find(
      (student) =>
        student.full_name.toLocaleLowerCase() ==
        this.exam.fullName.toLocaleLowerCase()
    );

    if (student) {
      this.exam.studentId = student.student_id;
      this.exam.email = student.email;
      this.exam.accessToken = student.access_token;
    } else {
      this.exam.studentId = '';
      this.exam.email = '';
      this.exam.accessToken = '';
    }
  }

  copyText(textToCopy: string) {
    // You can also use the Clipboard API if available
    navigator.clipboard.writeText(textToCopy).then(() => {
      console.log('Text copied to clipboard');
    });
  }

  copyExamInfo() {
    // copy exam link to clipboard
    let link = document.createElement('textarea');
    link.value =
      'Link da prova:\n' +
      this.examLink +
      '\n\nCódigo de acesso:\n' +
      this.exam.accessToken;

    document.body.appendChild(link);
    link.select();
    document.execCommand('copy');
    document.body.removeChild(link);

    return false;
  }

  get sumbitButtonDisabled() {
    return (
      (this.examTarget === 'student' && !this.student) ||
      !this.exam.fullName ||
      !this.exam.startTime ||
      !this.exam.duration ||
      !this.exam.email ||
      this.examsService.isLoading
    );
  }

  get examLink() {
    return `${this.examsService.endpoints.frontend}stage/${this.stageId}/validate-token/${this.exam.examId}`;
  }
}
