<h2 mat-dialog-title style="margin-left: -0.5rem;">
  @if(showExamLink){
  Prova Agendada!
  }
  @else {
  Prova {{data?.title}}
  }
</h2>
<mat-dialog-content>
  @if(!examTarget){
  <p>Escolha para quem deseja agendar a prova.</p>

  <div class="grid grid-flow-col gap-4 w-full mt-4">

    <button matRipple class="border" (click)="examTarget = 'student'">
      <div class="flex flex-col px-4 py-6 items-center text-blue-700">
        <mat-icon class="mb-2">school</mat-icon>
        <span>Aluno</span>
      </div>
    </button>

    <button matRipple class="border" (click)="examTarget = 'lead'">
      <div class="flex flex-col px-4 py-6 items-center text-blue-700">
        <mat-icon class="mb-2">support_agent</mat-icon>
        <span>Lead</span>
      </div>
    </button>

  </div>
  }
  @else if(showExamLink){
  <div class="flex flex-col">

    <mat-form-field>
      <mat-label>Link da prova</mat-label>
      <input matInput type="text" [value]="examLink" readonly>

      <button mat-icon-button matSuffix (click)="copyText(examLink)">
        <mat-icon>content_copy</mat-icon>
      </button>

    </mat-form-field>

    <mat-form-field>
      <mat-label>Código de acesso</mat-label>
      <input matInput type="text" [value]="exam.accessToken" readonly>

      <button mat-icon-button matSuffix (click)="copyText(exam.accessToken||'')">
        <mat-icon>content_copy</mat-icon>
      </button>
    </mat-form-field>

  </div>
  }
  @else{
  <div class="grid grid-cols-2 max-md:grid-cols-1 gap-x-5 pt-2">

    @if (examTarget === 'student'){

    <mat-form-field class="md:col-span-2">
      <mat-label>Nome do Aluno</mat-label>
      <input type="text" aria-label="Nome do aluno" matInput [(ngModel)]="student" [matAutocomplete]="auto"
        #studentNameInput required (keyup)="onSearch()">

      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onStudentSelected($event)"
        [requireSelection]="true">
        @for (option of options; track option) {
        <mat-option [value]="option">
          <div class="py-2">
            <app-profile-picture [user]="option"></app-profile-picture>
          </div>
        </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>

    @if(student && student.classroom_name && student.school_name){
    <mat-form-field>
      <mat-label>Turma</mat-label>
      <input matInput type="text" [readonly]="true" [value]="student.classroom_name" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Escola</mat-label>
      <input matInput type="text" [readonly]="true" [value]="student.school_name" />
    </mat-form-field>
    }

    }
    @else if (examTarget === 'lead'){

    <mat-form-field>
      <mat-label>Nome do Aluno</mat-label>
      <input matInput type="text" [(ngModel)]="exam.fullName" name="fullName" required list="students"
        (input)="getStudentEmail()" />
    </mat-form-field>

    <datalist id="students">
      @for(student of students; track student){
      <option value="{{student.full_name}}"></option>
      }
    </datalist>

    }

    <mat-form-field [className]="examTarget === 'student' ? 'md:col-span-2' : ''">
      <mat-label>E-mail</mat-label>
      <mat-icon matSuffix>mail</mat-icon>
      <input matInput type="email" [(ngModel)]="exam.email" name="email" required />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Data de Início</mat-label>
      <input matInput [matDatepicker]="picker" [(ngModel)]="exam.startTime" required />
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Duração em minutos</mat-label>
      <mat-icon matSuffix>schedule</mat-icon>
      <input matInput type="number" [(ngModel)]="exam.duration" name="duration" required [readonly]="true" />
    </mat-form-field>
  </div>
  }
</mat-dialog-content>
@if(examTarget){
<mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancelar</button>
  @if(showExamLink){
  <button mat-flat-button (click)="copyExamInfo()">
    <mat-icon>content_copy</mat-icon>
    Copiar link e código
  </button>
  }
  @else {
  <button mat-flat-button (click)="submitForm()" [disabled]="sumbitButtonDisabled">
    <mat-icon>calendar_month</mat-icon>
    Agendar
  </button>
  }
</mat-dialog-actions>
}