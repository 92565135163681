import { inject, Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { convertObjectToSnakeCase } from '../utils/convertObjectToSnakeCase.util';
import { tap } from 'rxjs';

export type GradeAssignment = {
  studentId: string;
  stage?: string;
  stageId: number;
  classroomId: string;
  callanApp: number;
  quiz: number;
  speaking: number;
  dictation: number;
  grammar: number;
  average?: number;
  attendance?: number;
};

@Injectable({
  providedIn: 'root',
})
export class GradeAssignmentService {
  private apiService = inject(ApiService);

  dataSource: GradeAssignment[] = [];

  post(gradeAssignment: GradeAssignment) {
    return this.apiService.post(
      'students/stage_score',
      convertObjectToSnakeCase(gradeAssignment)
    );
  }

  getAll(studentId: string) {
    return this.apiService
      .get<GradeAssignment[]>(`students/stage_score/${studentId}`)
      .pipe(
        tap((data) => {
          this.dataSource = data;
        })
      );
  }
}
