<mat-dialog-content>

    <div #printSection>

        <div style="display: grid; grid-auto-flow: row; gap: 20px;">

            <img src="/assets/printable-logo.png" alt="Logo USK" width="200px" style="margin: auto;">

            <h4 style="font-size: 1.5rem; margin: 10px 0; text-align: center;">Boletim Escolar</h4>

            <table>
                <tbody>
                    <tr>
                        <td style="font-weight: bold;">Aluno</td>
                        <td>{{data.studentName}}</td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold;">Turma</td>
                        <td>{{data.classroomName}}</td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold;">Estágio</td>
                        <td>{{data.stageName}}</td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <tr>
                        <th>Matéria</th>
                        <th>Nota</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Grammar</td>
                        <td>{{data.grammar}}</td>
                    </tr>
                    <tr>
                        <td>Quiz</td>
                        <td>{{data.quiz}}</td>
                    </tr>
                    <tr>
                        <td>Speaking</td>
                        <td>{{data.speaking}}</td>
                    </tr>
                    <tr>
                        <td>Dictation</td>
                        <td>{{data.dictation}}</td>
                    </tr>
                    <tr>
                        <td>Callan App</td>
                        <td>{{data.callanApp ?? 0}}</td>
                    </tr>
                </tbody>
            </table>

            <table>
                <tbody>
                    <tr>
                        <td style="font-weight: bold;">Média</td>
                        <td>{{data.average}}</td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold;">Presença</td>
                        <td>{{data.attendance}}%</td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold;">Status</td>
                        <td>
                            @let attendance_approved = data.attendance >= APPROVAL_CRITERIA.ATTENDANCE;
                            @let average_approved = data.average >= APPROVAL_CRITERIA.AVERAGE;
                            @let approved = attendance_approved && average_approved;

                            @if(!data.attendance || !data.average){
                            <span>--</span>
                            }
                            @else if(approved){
                            <span>Aprovado</span>
                            }
                            @else {
                            <span>Reprovado</span>
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button matDialogClose>Cancelar</button>
    <button mat-flat-button (click)="printComponent()">
        <mat-icon class="mat-18">print</mat-icon>
        Imprimir
    </button>
</mat-dialog-actions>