<table mat-table [dataSource]="gradeAssignmentService.dataSource" class="table !shadow-none !cursor-auto">

  <ng-container matColumnDef="stage">
    <th mat-header-cell *matHeaderCellDef>Estágio</th>
    <td mat-cell *matCellDef="let assignment">
      <span class="text-nowrap">
        {{assignment.stage}}
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="grammar">
    <th mat-header-cell *matHeaderCellDef>Grammar</th>
    <td mat-cell *matCellDef="let assignment">
      <span>{{assignment.grammar ?? '--'}}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="quiz">
    <th mat-header-cell *matHeaderCellDef>Quiz</th>
    <td mat-cell *matCellDef="let assignment">
      <span>{{assignment.quiz ?? '--'}}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="dictation">
    <th mat-header-cell *matHeaderCellDef>Dictation</th>
    <td mat-cell *matCellDef="let assignment">
      <span>{{assignment.dictation ?? '--'}}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="speaking">
    <th mat-header-cell *matHeaderCellDef>Speaking</th>
    <td mat-cell *matCellDef="let assignment">
      <span>{{assignment.speaking ?? '--'}}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="callanApp">
    <th mat-header-cell *matHeaderCellDef>Callan App</th>
    <td mat-cell *matCellDef="let assignment">
      <span>{{assignment.callanApp ?? '--'}}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="average">
    <th mat-header-cell *matHeaderCellDef>Média</th>
    <td mat-cell *matCellDef="let assignment">
      @if(!assignment.average){
      <span>--</span>
      }
      @else if( assignment.average >= APPROVAL_CRITERIA.AVERAGE) {
      <span class="font-medium text-green">{{assignment.average}}%</span>
      }
      @else {
      <span class="font-medium text-red">{{assignment.average}}</span>
      }
    </td>
  </ng-container>

  <ng-container matColumnDef="attendance">
    <th mat-header-cell *matHeaderCellDef>Presença</th>
    <td mat-cell *matCellDef="let assignment">
      @if(!assignment.attendance){
      <span>--</span>
      }
      @else if( assignment.attendance >= APPROVAL_CRITERIA.ATTENDANCE) {
      <span class="font-medium text-green">{{assignment.attendance}}%</span>
      }
      @else {
      <span class="font-medium text-red">{{assignment.attendance}}%</span>
      }
    </td>
  </ng-container>

  <ng-container matColumnDef="approval">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let assignment">
      @if(!assignment.attendance || !assignment.average){
      <span>--</span>
      }
      @else if(assignment.attendance >= APPROVAL_CRITERIA.ATTENDANCE && assignment.average >= APPROVAL_CRITERIA.AVERAGE){
      <chip color="green">Aprovado</chip>
      }
      @else {
      <chip color="red">Reprovado</chip>
      }
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let assignment">
      <button mat-icon-button [matMenuTriggerFor]="options">
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #options="matMenu">
        <button mat-menu-item (click)="openGradeAssignmentDialog(assignment.stageId, assignment)">
          <mat-icon>edit</mat-icon>
          <span class="font-normal text-light">Editar</span>
        </button>
        @if(!!assignment.average){
        <button mat-menu-item (click)="openPrintableReportCard(assignment)">
          <mat-icon>description</mat-icon>
          <span class="font-normal text-light">Boletim</span>
        </button>
        }
      </mat-menu>

    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>