import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { APPROVAL_CRITERIA } from '../report-card/report-card.component';

const STYLES = `
body { font-family: Arial, sans-serif; }

table {
  width: 100%;
  border-collapse: collapse;
}
th,
td {
  border: 1px solid #ccc;
  padding: 1rem;
  text-align: left;
}
`;

export type PrintableReportCardDialogData = {
  studentName: string;
  stageName: string;
  classroomName: string;
  quiz: number;
  grammar: number;
  average: number;
  speaking: number;
  dictation: number;
  attendance: number;
  callanApp?: number;
  status: string;
};

@Component({
  selector: 'app-printable-report-card',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatButtonModule,
    MatIconModule
  ],
  templateUrl: './printable-report-card.component.html',
  styles: [STYLES],
})
export class PrintableReportCardComponent {
  @ViewChild('printSection', { static: false }) printSection!: ElementRef;

  readonly data = inject<PrintableReportCardDialogData>(MAT_DIALOG_DATA);

  APPROVAL_CRITERIA = APPROVAL_CRITERIA

  printComponent() {
    const printContent = this.printSection.nativeElement.innerHTML;
    const newWindow = window.open('', '', 'width=800,height=600');

    if (newWindow) {
      newWindow.document.open();
      newWindow.document.write(`
          <html>
            <head>
              <title>Boletim - ${this.data.studentName} - ${this.data.stageName}</title>
              <style>${STYLES}</style>
            </head>
            <body>
              ${printContent}
              <script>
                window.onload = function() {
                  setTimeout(() => {
                    window.print();
                  }, 500); // Delay to allow rendering
                };
              </script>
            </body>
          </html>
        `);
      newWindow.document.close();
    } else {
      console.error('Failed to open print window');
    }
  }
}
