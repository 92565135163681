<h1 matDialogTitle>Stage {{data.stageId}}</h1>

<mat-dialog-content>

    <form [formGroup]="form" class="grid grid-flow-row md:grid-cols-2 md:gap-x-5">
        <mat-form-field>
            <mat-label>Grammar</mat-label>
            <input matInput formControlName="grammar" />
        </mat-form-field>

        <mat-form-field>
            <mat-label>Quiz</mat-label>
            <input matInput formControlName="quiz" />
        </mat-form-field>

        <mat-form-field>
            <mat-label>Dictation</mat-label>
            <input matInput formControlName="dictation" />
        </mat-form-field>

        <mat-form-field>
            <mat-label>Speaking</mat-label>
            <input matInput formControlName="speaking" />
        </mat-form-field>

        <mat-form-field>
            <mat-label>Callan App</mat-label>
            <input matInput formControlName="callanApp" />
        </mat-form-field>

        <mat-form-field>
            <mat-label>Média</mat-label>
            <input matInput [readonly]="true" [value]="totalGrade" />
        </mat-form-field>
    </form>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button matDialogClose>Cancelar</button>
    <button mat-flat-button (click)="saveGrade()">Salvar</button>
</mat-dialog-actions>