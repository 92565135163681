import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

type ExamStudent = {
  student_id?: string;
  full_name: string;
  email: string;
};

@Injectable({
  providedIn: 'root',
})
export class ExamsService {
  public endpoints = {
    frontend: 'https://exam.uskinglesintensivo.com.br/',
  };

  private apiUrlV1 = 'https://uskinglesintensivo.com.br/provas-online/api/v1/';
  private apiUrlV2 = environment.examsUrl;

  private http = inject(HttpClient);
  public isLoading = false;

  constructor() {}

  get(endpoint: string, params?: any): Observable<any> {
    this.isLoading = true;

    return this.http.get<any>(`${this.apiUrlV1}/${endpoint}`, { params }).pipe(
      finalize(() => {
        this.isLoading = false;
      })
    );
  }

  createStudent(examStudent: ExamStudent): Observable<any> {
    this.isLoading = true;

    return this.http.post<any>(`${this.apiUrlV2}/students/`, examStudent).pipe(
      finalize(() => {
        this.isLoading = false;
      })
    );
  }
}
