@if(notFound){
<app-not-found></app-not-found>
}
@else if(!aluno){
<app-loading-screen></app-loading-screen>
}


<div class="flex flex-col gap-5">

    @if(aluno){

    <div class="flex flex-col md:flex-row gap-5">
        <div class="card p-5 w-full flex flex-row items-center">
            <app-profile-picture [user]="aluno" [size]="'big'">
                @if(classroom){
                <div class="flex flex-row gap-3 mt-2">
                    <p class="text-light">
                        {{classroom.name}}
                    </p>
                    @if(classroom.active){
                    <chip color="green">Ativo</chip>
                    }
                    @else {
                    <chip color="red">Inativo</chip>
                    }
                </div>
                }
            </app-profile-picture>

            <!-- Incluir estatísticas de presença e engajamento -->

        </div>

        <div class="flex flex-col justify-between gap-2">
            <button mat-raised-button (click)="openOcorrenciaModal()">
                <mat-icon class="mat-18">flag</mat-icon>
                <span class="text-nowrap">
                    Abrir ocorrência
                </span>
            </button>

            <button mat-raised-button disabled class="max-md:hidden"></button>
            <button mat-raised-button disabled class="max-md:hidden"></button>
        </div>
    </div>
    }

    <div class="card flex flex-col gap-5" [ngClass]="{'hidden': !aluno}">

        <mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedIndexChange)="onTabChange($event)">

            <mat-tab label="Dados Pessoais">
                <div class="px-5">
                    <app-pessoa-form #alunoForm></app-pessoa-form>
                </div>
            </mat-tab>

            @if(!aluno || aluno.responsavelId){
            <mat-tab label="Responsável">
                <div class="px-5">
                    <app-pessoa-form #respForm></app-pessoa-form>
                </div>
            </mat-tab>
            }

            <mat-tab [label]="'Ocorrências (' + ocorrencias.length + ')'">
                @for(ocorrencia of ocorrencias; track ocorrencia) {
                <div class="flex flex-col gap-3 p-5">

                    <div class="flex flex-row gap-3">
                        <app-profile-picture [user]="{id:ocorrencia.created_by}">
                        </app-profile-picture>
                        <div class="flex flex-col">
                            <div class="flex flex-row gap-2 items-center">
                                <span class="font-medium">{{ocorrencia.creator_name}}</span>
                                <span class="text-light text-sm" [matTooltip]="formatDateTime(ocorrencia.created_at)"
                                    matTooltipPosition="above">
                                    {{calcularTempoPassado(ocorrencia.created_at)}}
                                </span>
                            </div>
                            <div class="flex flex-row gap-2 text-sm">
                                <span class="font-medium">{{ocorrencia.classroom_name}}</span>
                                <span class="text-light">
                                    {{ocorrencia.school_name}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <p class="text-light">
                        {{ocorrencia.description}}
                    </p>
                </div>
                }
                @empty{
                <p class="p-5">
                    Não há ocorrências
                </p>
                }
            </mat-tab>

            <mat-tab [label]="'Materiais Didáticos ('+ receivedMaterials +')'">
                <app-student-material-list [studentId]="alunoId"
                    [(receivedMaterials)]="receivedMaterials"></app-student-material-list>
            </mat-tab>

            <mat-tab label="Boletins">
                @if(classroom && aluno) {
                <app-report-card [studentId]="alunoId" [classroomId]="classroom.id" [studentName]="aluno.name"
                    [classroomName]="classroom.name"></app-report-card>
                }
            </mat-tab>

        </mat-tab-group>

    </div>

</div>