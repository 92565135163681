@let videoTitle = data.videoType == videoTypes.NEW_WORK? 'New Work': 'Revision';
<h2 mat-dialog-title style="margin-left: -0.5rem;">
    {{videoTitle}}
</h2>

<mat-dialog-content>

    @if(linkControl.valid && uploadMessage){
    <div class="flex flex-col gap-4">
        <p>1 link anexado</p>

        <div class="flex flex-row gap-2 items-center bg-blue-50 rounded p-3">
            <img src="../../../assets/video-file-icon.png" alt="video file icon" width="40px">
            <p class="font-medium">{{videoTitle}}</p>
        </div>

        <div class="flex flex-row items-center gap-2">
            <p>{{ uploadMessage }}</p>
            <mat-icon class="text-green-600" [inline]="true">check</mat-icon>
        </div>
    </div>
    }
    @else if(fileName){
    <div class="flex flex-col gap-4">

        <p>1 arquivo anexado</p>

        <div class="flex flex-row gap-2 items-center bg-blue-50 rounded p-3">
            <img src="../../../assets/video-file-icon.png" alt="video file icon" width="40px">
            <p class="font-medium">{{fileName}}</p>
        </div>

        @if(uploadMessage){
        <div class="flex flex-row items-center gap-2">
            <p>{{ uploadMessage }}</p>
            <mat-icon class="text-green-600" [inline]="true">check</mat-icon>
        </div>
        }
        @else if(uploadProgress === 0){
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        <p>Isso pode levar uns minutos...</p>
        }@else if (uploadProgress !== undefined){
        <mat-progress-bar mode="determinate" [value]="uploadProgress"></mat-progress-bar>
        <p>Fazendo upload: {{uploadProgress}}%</p>
        }

    </div>
    }@else {
    <p class="!mb-4">
        Envie um vídeo de no mínimo 5 minutos de uma de suas aulas para receber o feedback mensal.
    </p>

    <mat-form-field appearance="outline" class="w-full">
        <mat-label>Turma correspondente a gravação</mat-label>
        <mat-select [formControl]="classroomControl">
            @for(classroom of classrooms; track classroom){
            <mat-option [value]="classroom.id">
                {{classroom.name}} - {{classroom.timeslot}}
            </mat-option>
            }
        </mat-select>
    </mat-form-field>

    @if(classroomControl.valid){
    <mat-form-field appearance="outline" class="w-full">
        <mat-label>Cole o link do vídeo</mat-label>
        <input matInput [formControl]="linkControl">
    </mat-form-field>

    <p class="text-link" (click)="uploadInput.click(); linkControl.setValue('')">Ou faça upload de um arquivo.</p>
    }

    }
    <input type="file" (change)="onFileSelected($event)" accept="video/mp4" #uploadInput class="hidden">

</mat-dialog-content>

<mat-dialog-actions>

    @if(uploadProgress == 100){
    <button mat-button (click)="close()">Ok</button>
    }
    @else if(fileName){
    <!-- Upload Video File -->
    <button mat-button (click)="close()" cdkFocusInitial [disabled]="apiService.isLoading">Cancelar</button>
    <button mat-flat-button (click)="onUpload()" [disabled]="apiService.isLoading || !selectedFile">
        <mat-icon class="mat-18">send</mat-icon>
        Enviar
    </button>
    }@else {
    <!-- Upload Video Link -->
    <button mat-button (click)="close()" cdkFocusInitial>Cancelar</button>
    <button mat-flat-button (click)="onUpload()" [disabled]="linkControl.invalid">
        <mat-icon>send</mat-icon>
        Enviar
    </button>
    }
</mat-dialog-actions>